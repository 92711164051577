.search-div .search-input {
    float: right;
}

.search-field {
    float: right;
}

.search-input {
    max-width: 10rem;
}

.back-btn {
    display: flex;
    flex-direction: row-reverse;
    height: 22px;
}

.delete-input .MuiOutlinedInput-root{
    padding: 1.5px !important;
    padding-left: 10px !important;
    min-width: 500px !important;
}
 
.group-id-input .MuiAutocomplete-popupIndicator{
    transform: none !important;
}


.MuiFormHelperText-contained{
    color: rgb(6, 182, 0) !important;
    word-spacing: 1px!important;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
}

.test-delete-dio{
    max-width: 200px !important;
}

.groupId-title {
    height: 15px !important;
    color: black;
    font-weight: bold;
}

.groupId-action-title {
    display: flex;
    float: right;
    padding-right: 150px;
}

.search-icon{
    display: flex  !important; 
    position: relative !important;
    float: right;
    top: 11px;
    right: 40px;      
}