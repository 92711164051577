.prodTeamSearchBtn{
    margin-left: 20px !important;
}

.search-input{
    min-width: 50rem !important;
}

.parent-part-info{
    background-color: #c9c1c1;
    display: flex;
    padding: 5px;
}

.groupid-btn{
    margin: 0 20px;
    color: #1A9FE0;
    font-size: 15px;
}

.groupid-icon{
    padding-bottom: 2px !important;
    font-size: 22px !important;
}

a :hover{
    text-decoration: none !important;
}

.groupid-tag{
    font-size: 12px !important;
    font-weight: bold;
    color: #1A9FE0;
    text-decoration: none;
}

.no-data{
    align-items: center !important;
    font-size: 16px !important;
}

.act-btn {
    font-size: 16px !important;
    color: #1A9FE0;
}

.act-item {
    font-size: 14px !important;
    color: #1A9FE0;
}

.input-container{
    max-width: 730px !important;
}

.MuiDialogContent-root .add-alt-dialog{
    padding: 0 !important;
}

.main-input-container .MuiDialogContent-root{
    padding: 0 !important;
    /* min-width: 800px !important; */
    overflow: hidden !important;
}

.main-input-container{
    padding: 0 10px;
}

.input-container-alt{
    max-width: 730px !important;
}

.action-title  {
    padding-left: 7rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.MuiTableRow-root th{
    padding: 15px !important;
}

.close-alt {
    color: #1A9FE0;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
}

.close-icon{
    height: 15px;
    width: 15px;
    margin-bottom: 3px;
    border-radius: 50%;
    background-color: #1A9FE0;
    color: white;
}

.popup-input{
    width: 22rem !important;
}

.popup-input-desc{
    width: 45rem !important;
}

.add-more{
    margin-right: -20px !important;
}

.act-container a{
    color: #1A9FE0 !important;
    text-decoration: none !important;
}

.act-btn-space{
    float: right;
}

.act-container{
    display: inline-flex;
    align-items: center;
}

.group-id-input .MuiOutlinedInput-root{
    padding: 1.5px !important;
    padding-left: 10px !important;
}
 
.group-id-input .MuiAutocomplete-popupIndicator{
    transform: none !important;
}

.add-alternate{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #1A9FE0;
    color: #EAEAEA;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 22px;
}

.add-alter-text{
    display: flex;
    cursor: pointer;
}

.add-alter-text h6{
    color: #1A9FE0;
    font-size: 15px;
    font-weight: bold;
    padding-top: 3px;
    margin-left: 8px;
}

.close-btn{
    display: flex;
    flex-direction: row-reverse;
}

.popup-dialog{
    padding: 0 30px !important;
}

.popup-input{
    display: inline-block;
}

.dialog-box{
    border-radius:15px !important ;
}

.dialog-cancel-btn{
    background-color: #EAEAEA !important;
    color: #666666 !important;
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-height: 35px;
}

.action-btn{
    display: inline-block !important;
}

/* .action-title{
    padding-left: 7rem !important;
} */

.bodyContent {
    color: #666666 !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    font-family: Nunito !important;
}

.tableHead {
    color: #133F8A;
    font-weight: 900;
    font-size: 0.75rem;
    font-family: Nunito;
    padding-top: 0px !important;
}

.MuiTableCell-head {
    line-height: 1rem !important;
}