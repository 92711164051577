.searchbarDashboard {
    background-color: #ffffff;
    min-height: 5rem;
    display: flex;
    align-items: center;
}

.enquiryDetails {
    background-color: #ffffff;
    min-height: 25rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    /* max-height: 30rem; */
}

.enquiryDetails-FSE {
    background-color: #ffffff;
    min-height: 25rem;
    /* max-height: 30rem; */
}

.searchbarOptions {
    color: #1A9FE0 !important;
}

.searchbarOptions .MuiIconButton-root {
    color: #1A9FE0 !important;
}

.totalCountTextSmallest{
    font-weight: 600 !important;
    font-size: 0.8rem !important;
    color: #133f8a !important;
    font-style:italic;
}

.addToEnquiryButton {
    color: #FFFFFF;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.3rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.blueLabelTable {
    color: #133f8a !important;
    font-weight: 900 !important;
    font-size: 12px !important;
    font-family: Nunito !important;
    padding: 0px !important;
}

.blueLabelHeaderTable {
    color: #133f8a !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    font-family: Nunito !important;
    padding: 16px !important;
}

.greyLabelTable {
    font-size: 9px !important;
    color: #444444 !important;
    font-weight: 800;
    font-family: Nunito !important;
}

.tableBottom {
    min-height: 3rem;
}

.bottomFieldNameTable {
    color: #1A9FE0 !important;
    font-size: 0.75rem;
    font-weight: bold;
}

.bottomFieldNameTable-view-price {
    color: #1A9FE0 !important;
    font-size: 0.85rem;
    font-weight: bold;
    text-decoration: underline !important;
}

.bottomFieldMessage {
    color: #c70707 !important;
    font-size: 1rem;
    font-weight: bold;
}

.bottomFieldMessage-confirm-order {
    color: #1A9FE0 !important;
    font-size: 1rem;
    font-weight: bold;
}

.bottomFieldValueTable {
    color: #444444 !important;
    font-size: 12px;
    font-weight: 700;
    font-family: Nunito !important;
}

.borderRightBottom {
    border-right: 1px solid #dfe1e5;
    border-top: 1px solid #dfe1e5;
}
.borderTop {
    border-top: 1px solid #dfe1e5;
}

.borderBottom {
    border-top: 1px solid #dfe1e5;
    padding-top: 3px !important;
}
.borderBottomSpecialPrice{
    border-bottom:  1px solid #dfe1e5 !important;
}

.actionButtomButtomsBlue {
    color: #ffffff;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.actionButtomButtomsGrey {
    color: black !important;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    background-color: lightgray !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
    pointer-events: none !important;
    /* opacity: 0.7 !important; */
}

.gotoOrderHistory {
    color: #FFFFFF;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.actionButtomButtomsBlueDisabled {
    pointer-events: none !important;
    opacity: 0.7 !important;
}

.actionButtomButtoms {
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    border: 1px solid #dfe1e5 !important;
    border-radius: 2.5rem !important;
    height: 2.5rem !important;
    color: black !important;
    /* background-color: #1A9FE0 !important; */
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 11px !important;
}

.referenceNumberText {
    border: 1px solid #dfe1e5;
    background: white;
    border-radius: 1.5rem !important;
    height: 1.9rem;
    padding: 0px 15px;
    font-size: 11px;
    color: #999999 !important;
    font-family: Nunito !important;
    font-weight: 600 !important;
    width: 170px;
}

.referenceNumberText::placeholder {
    font-size: 0.8rem;
}

.referenceNumberText:focus {
    outline: none;
}

.availabilityDropdown {
    min-width: 150px !important;
}

.smallcircularinputFiled {
    text-align: center !important;
    max-width: 2.5rem;
    border: 1px solid #dfe1e5;
    background: white;
    border-radius: 11px !important;
    height: 1.5rem;
    padding: 0.3px 0.2rem;
    font-size: 10px;
}

.smallcircularinputFiled::placeholder {
    font-size: 0.9rem;
}

.smallcircularinputFiled:focus {
    outline: none;
}

.smallreactangleinputFiled {
    max-width: 1.5rem;
    border: 1px solid #dfe1e5;
    background: white;
    border-radius: 0.2rem;
    height: 1.5rem;
    padding: 0.3px 0.2rem;
    font-size: 0.9rem;
}

.smallreactangleinputFiled::placeholder {
    font-size: 0.9rem;
}

.smallreactangleinputFiled:focus {
    outline: none;
}

.dropzoneImport {
    min-height: 10rem;
    background: #F9F9F9;
    border: 2px dashed #CCCCCC;
    border-radius: 25px;
}

.dragHereText {
    color: #666666;
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.dragHereIcon {
    color: #666666;
    font-size: 2.5rem;
}

.closeImportButton {
    background: #EAEAEA !important;
    border-radius: 40px !important;
    color: #666666 !important;
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.widthfifty {
    min-width: 18px !important;
}

.width-seventy-five {
    min-width: 75px !important;
}

.invalidRowClass * {
    color: red !important;
    border-top: none;
}

.invalidRowClassInvalid * {
    border-top: 1px solid #fc9d9d  !important;
}

.invalidRowClassInvalidPart * {
    border-top: none !important;
}

.fontsizetenew {
    font-size: 10px !important;
    border-top: none;
}

.legendItemCreateEnquiry {
    font-size: 0.75rem;
    color: #666666;
    font-weight: 500;
    font-family: Nunito !important;
}

.specialpriceFSE {
    height: 12px;
    width: 12px;
    background-color: #B300B2;
    border-radius: 50%;
    display: inline-block;
}

.queuedVarificationFSE {
    color: #1A9FE0;
    background-color: #1A9FE0;
}

.legendItemCreateEnquiry .legendItemLabel {
    margin-left: 4px;
}

.legendItemCreateEnquiry .dot {
    height: 12px;
    width: 12px;
    background-color: #00FF20;
    border-radius: 50%;
    display: inline-block;
}

.legendItemspr .legendItemLabel {
    margin-left: 4px;
}

.legendItemLabelFSE {
    margin-left: 4px;
    font-size: 0.85rem;
}

.legendItemspr .dot-red {
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.legendItemCreateEnquiry .dot-blue {
    height: 12px;
    width: 12px;
    background-color: #1A9FE0;
    border-radius: 50%;
    display: inline-block;
}

.dotlegendItemCreateEnquiry {
    position: absolute;
    left: -20px;
    top: 5px;
    height: 12px;
    width: 12px;
    background-color: #00FF20;
    border-radius: 50%;
    display: inline-block;
}

.dotlegendItemProcCreateEnquiry {
    position: absolute;
    left: -15px;
    top: 5px;
    height: 12px;
    width: 12px;
    background-color: #f6c23e;
    border-radius: 50%;
    display: inline-block;
}

.legendItemCreateEnquiry .dot-procurement {
    height: 12px;
    width: 12px;
    background-color: #f6c23e;
    border-radius: 50%;
    display: inline-block;
}

.dotlegendItemBlueCreateEnquiry {
    position: absolute;
    left: -20px;
    top: 5px;
    height: 12px;
    width: 12px;
    background-color: #1A9FE0;
    border-radius: 50%;
    display: inline-block;
}

.dotlegendItemRedCreateEnquiry {
    position: absolute;
    left: -20px;
    top: 5px;
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.dotlegendItemCreateEnquirySupersede {
    position: absolute;
    left: -20px;
    top: 5px;
    height: 12px;
    width: 12px;
    background-color: #1A9FE0;
    border-radius: 50%;
    display: inline-block;
}

.tandcHeading {
    font-size: 0.75rem;
    color: #666666;
    font-weight: 700;
    font-family: Nunito !important;
}

.tandcHeading-font {
    font-size: 13px;
    color: black !important;
    font-weight: 700;
    font-family: Nunito !important;
}

.tandcHeading-font-type {
    font-size: 13px;
    color: rgb(238, 137, 5) !important;
    font-weight: 700;
    font-family: Nunito !important;
}

.tandcBody {
    font-size: 0.75rem;
    color: #666666;
    font-weight: 500;
    font-family: Nunito !important;
}

.button-font-changes {
    font-family: Nunito !important;
    font-weight: 600 !important;
    color: #1A9FE0 !important;
}

.load-from-fav {
    font-family: Nunito !important;
    font-weight: 600 !important;
}

.import-button-disabled {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 45px !important;
    padding-right: 45px !important;
    height: 2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
    pointer-events: none !important;
    opacity: 0.7 !important;
}

.checkbox {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiCheckbox-colorPrimary {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.add-to-enquiry-button {
    color: #FFFFFF;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.offer {
    font-family: Nunito !important;
    font-size: 13px;
    font-weight: 700;
    color: #666666;
}

.partNumberYextTable {
    font-weight: 600;
    color: #a0a0a0 !important;
    font-size: 8px !important;
    font-family: Nunito !important;
}

.innergreyLabelTable {
    font-size: 10px !important;
    color: #444444 !important;
    font-weight: 700;
    font-family: Nunito !important;
}
.disableInnergreyLabelTable {
    font-size: 10px !important;
    color: #a0a0a0 !important;
    font-weight: 700;
    font-family: Nunito !important;
}
/* .enquiryDetails tbody{
    max-height: 509px;
    overflow-y: scroll;
    display: block;
    min-width: 100%;
}
.enquiryDetails tr{
    vertical-align: middle;
    display: table;
    width: 100%;
    table-layout: fixed;
} */

.customTableContainerCreateEnquiry{
    width: 100%;
    overflow-x: auto;
}

.tableRootCreateEnquiry{
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHeadCreateEnquiry{
    display: table-header-group;
}

.tableRowCreateEnquiry{
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableRowPanelsCreateEnquiry{
    background: rgba(17, 17, 17, 0.05);
    color: inherit;
    /* outline: 0; */
    /* vertical-align: middle; */
}

.tdPanelsCreateEnquiry{
    white-space: nowrap;
    display: table-cell;
    padding: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    font-size: 0.75rem !important;
    font-family: 'Nunito', sans-serif !important;
}

.thCreateEnquiry{
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    padding: 16px;
    line-height: 1rem !important;
    font-size: 0.75rem !important;
    font-family: 'Nunito', sans-serif !important;
    text-align: left;
}


.tdCreateEnquiry{
    font-family: 'Nunito', sans-serif !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-align: left;
}

.tdchildCreateEnquiry{
    font-family: 'Nunito', sans-serif !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.43;
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-align: left;
}
.superseededRow td{
    background-color: #fed4d4 !important;
}
.catalogue-fill{
    fill: #1A9FE0 !important;
    color: blue !important;
}

.mt-13px {
    margin-top: 12px !important;
}

.ml-10px{
    margin-left: 10px !important;
}